<template>
    <div class="page_bank">
        <el-radio-group v-model="type">
            <el-radio-button label="0">Все</el-radio-button>
            <el-radio-button label="Credit">Входящие</el-radio-button>
            <el-radio-button label="Debit">Исходящие</el-radio-button>
        </el-radio-group>
        <el-table :data="this.type != 0 ? transactions.filter(e => e.creditDebitIndicator == this.type) : transactions" stripe style="width: 100%">
            <el-table-column prop="documentProcessDate" width="100px" class-name="date"></el-table-column>
            <el-table-column prop="Amount.amount" width="150px">
                <template slot-scope="scope">
                    <div v-if="scope.row.creditDebitIndicator == 'Debit'" class="debit">-{{scope.row.Amount.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}} ₽</div>
                    <div v-if="scope.row.creditDebitIndicator == 'Credit'" class="credit">{{scope.row.Amount.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}} ₽</div>
                </template>
            </el-table-column>
            <el-table-column class="column">
                <template slot-scope="scope">
                    <div class="CreditorParty" v-if="scope.row.creditDebitIndicator == 'Debit'">{{scope.row.CreditorParty.name}}</div>
                    <div class="CreditorParty" v-if="scope.row.creditDebitIndicator == 'Credit'">{{scope.row.DebtorParty.name}}</div>
                    <div class="description">{{scope.row.description}}</div>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
export default {
    data() {
        return {
            page: 1,
            type: 0
        }
    },
    created() {
       this.getTransactions(this.page);  
       //this.statementInit();
    },
    computed: {
        ...mapGetters(["transactions"]),
    },
    methods: {
         ...mapActions(["getTransactions", "statementInit"]), 
    }
}
</script>

<style>
.debit {
    color: #F44336;
    font-weight: 600;
    font-size: 16px;
}
.credit {
    font-weight: 600;
    font-size: 16px;
    color: #4CAF50
}
.page_bank {
    padding: 40px;
}
.CreditorParty {
    color: #424242;
    font-weight: 600;
}
.description {
        font-size: 12px;
    color: #a5a5a5;
}

.date {
    color: #424242;
    font-weight: bold;
}

</style>